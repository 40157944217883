import * as React from 'react'
import style from './manage-trial.css'
import { observer } from 'mobx-react-lite'
import { Loader } from 'components/loader'
import { DashboardLayout } from 'modules/dashboard/common/dashboard-layout'
import { useTranslation } from 'react-i18next'
import { useUpdateCurrentUser } from 'utils/hooks/use-update-current-user'
import { useStores } from 'utils/hooks/useStores'
import classNames from 'classnames'
import { mixpanelActions } from 'utils/mixpanelActions'
import { ModalLayerStore } from 'stores/modal-layer-store'
import { AddUserReturnValue } from 'interfaces/utils/add-user'
import { ManageTrial } from '../manage-trials/manage-trial'
import {
  Table,
  SearchInputField,
  Heading,
  Icons,
  Status,
  IconButton,
  Pagination,
  Tooltip,
} from 'plume-ui'
import { Heading as TableHeader } from 'plume-ui/dist/components/Table/Table'
import { ManageTrialsStore } from './manage-trials-store'
import { ICompany } from 'interfaces/api/portal/company-api'
import { howManyDaysLeft } from 'helpers/company-helpers'
import { isDateAfterNow, matchesCaseInsensitive } from 'helpers/general-helpers'
import { Redirect } from 'react-router-dom'
import { Nda } from '../nda/nda'
import { canAccess } from 'modules/auth/auth-action-permission'

const COMPANIES_PER_PAGE = 10

const showEditMTModal = async (
  actionModalStore: ModalLayerStore,
  manageTrialsStore: ManageTrialsStore,
  editPartnerId: string,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      manageTrialsStore.clearServerError()
      actionModalStore.removeModal()
      return res(closeButton)
    }

    manageTrialsStore.clearServerError()

    actionModalStore.addModal(
      <ManageTrial
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        editPartnerId={editPartnerId}
        manageTrialsStore={manageTrialsStore}
      />,
    )
  })
}

export const ManageTrials = observer(() => {
  const { t } = useTranslation()
  const appStore = useStores()
  const currentUser = useUpdateCurrentUser()

  const [manageTrialsStore] = React.useState(new ManageTrialsStore(appStore))
  const { allSuites } = manageTrialsStore

  const [searchString, setSearchString] = React.useState('')
  const [tablePage, setTablePage] = React.useState(0)
  React.useEffect(() => {
    mixpanelActions.track('Manage partners - Open', {})
    if (!!currentUser && manageTrialsStore.allSuites === null) {
      manageTrialsStore.listSuites()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manageTrialsStore, currentUser])

  if (!currentUser) {
    return <Loader />
  }

  if (
    !canAccess('viewManageTrials', currentUser) ||
    !appStore.authStore.currentUser.companies?.[0]?.subordinateCompanies?.length
  ) {
    return <Redirect to="/" />
  }

  if (!appStore.authStore.currentUser.companies?.[0]?.signedNdaTimestamp) {
    return <Nda />
  }

  const getHeaderRow = () => {
    const headerRow: TableHeader[] = [
      {
        name: t('btn.edit'),
        fieldName: 'edit',
        sortable: false,
        render: row => (
          <IconButton
            onClick={() =>
              showEditMTModal(appStore.actionModalStore, manageTrialsStore, row.partnerId)
            }
          >
            <Icons.DotsVerticalIcon className={style.iconSize} />
          </IconButton>
        ),
      },
      {
        name: t('accountManager.companyName'),
        fieldName: 'name',
        render: row => (
          <Tooltip openInPortal label={row.name} alignment="left">
            <div className={style.limitTextWidth}>{row.name}</div>
          </Tooltip>
        ),
      },
      {
        name: t('accountManager.partnerId'),
        fieldName: 'partnerId',
      },
    ]

    allSuites.forEach(suite => {
      headerRow.push({
        name: suite.label,
        fieldName: suite.label,
        sortable: false,
        render: (row: { productsInfo?: ICompany['productsInfo'] }) => {
          const isPurchased = !!row.productsInfo[suite.label]?.isPurchased

          const trialDuration = row.productsInfo[suite.label]?.trialEndDate
            ? howManyDaysLeft(row.productsInfo[suite.label]?.trialEndDate)
            : null
          return (
            <>
              {(isPurchased || trialDuration !== null) && (
                <Status
                  color={
                    isPurchased
                      ? 'ok'
                      : isDateAfterNow(row.productsInfo[suite.label]?.trialEndDate)
                      ? 'warning'
                      : 'error'
                  }
                  label={
                    isPurchased
                      ? t('common.active')
                      : isDateAfterNow(row.productsInfo[suite.label]?.trialEndDate)
                      ? t('manageTrials.expiresIn', {
                          dayOrDays: t('common.day', { count: trialDuration }),
                          numOfDays: trialDuration,
                        })
                      : t('home.trialExpired')
                  }
                />
              )}
            </>
          )
        },
      })
    })

    return headerRow
  }

  const elligibleCompanies = currentUser.companies?.[0]?.subordinateCompanies?.filter(
    c => !!c.portalAccountState && c.portalAccountState !== 'Terminated' && !!c.signedNdaTimestamp,
  )

  const searchFilteredData = elligibleCompanies?.filter(
    c =>
      matchesCaseInsensitive(c.name, searchString) ||
      matchesCaseInsensitive(c.portalNickname, searchString) ||
      matchesCaseInsensitive(c.partnerId, searchString),
  )
  return (
    <DashboardLayout currentUser={currentUser}>
      {allSuites === null ? (
        <Loader theme="small" rootClassName={style.loaderRoot} />
      ) : (
        <div className={style.pageRoot}>
          <Heading size="xxl">{t('manageTrials.title')}</Heading>
          <SearchInputField
            min={3}
            onChange={e => setSearchString(e.target.value || '')}
            placeholder={t('common.search')}
            messages={[{ status: 'hint', message: t('manageTrials.searchByNameOrPartnerId') }]}
            value={searchString}
          />
          <div className={style.tableWrapper}>
            <Table
              classes={curr => ({
                ...curr,
                root: classNames(curr.root, style.override, style.tableRoot),
              })}
              truncateCellContent={false}
              noResultsMessage={t('header.noResults')}
              headerRow={getHeaderRow()}
              dataRows={
                searchFilteredData.slice(
                  tablePage * COMPANIES_PER_PAGE,
                  (tablePage + 1) * COMPANIES_PER_PAGE,
                ) as any
              }
            />
          </div>
          <Pagination
            classes={curr => ({
              ...curr,
              root: classNames(curr.root, style.paginationCustomization),
            })}
            expandDirection="top"
            currentPage={tablePage}
            onPageSelect={setTablePage}
            totalPageCount={Math.ceil(searchFilteredData?.length / COMPANIES_PER_PAGE)}
          />
        </div>
      )}
    </DashboardLayout>
  )
})
