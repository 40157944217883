import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { Loader } from 'components/loader'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import style from './manage-inventory.css'
import { mixpanelActions } from 'utils/mixpanelActions'
import classNames from 'classnames'
import {
  Table,
  Status,
  Modal,
  ModalHeader,
  ModalBody,
  SearchInputField,
  Pagination,
  Button,
  Dropdown,
  Icons,
  DropdownItem,
  Spinner,
} from 'plume-ui'
import { Heading as TableHeader } from 'plume-ui/dist/components/Table/Table'
import { ManageInventoryStore } from './manage-inventory-store'
import { matchesCaseInsensitive } from 'helpers/general-helpers'
import { Tooltip } from 'components/tooltip'
import moment from 'moment'
import JSONPretty from 'react-json-pretty'
import { IGetInventoryNodeInfoResponse, INodeInfo } from 'interfaces/api/portal/i.inventory-api'

const jsonPrettyTheme = require('react-json-pretty/dist/monikai')

const LiveNodeCheck = observer((props: { nodeId: string }) => {
  const { t } = useTranslation()
  const appStore = useStores()

  const [isNodeInfoLoading, setIsNodeInfoLoading] = React.useState(false)
  const [nodeData, setNodeData] = React.useState<IGetInventoryNodeInfoResponse>(null)

  React.useEffect(() => {
    const fetchNodeInfo = async () => {
      if (isNodeInfoLoading && !nodeData && props.nodeId) {
        const nodeResponse = await appStore.portalInventoryApi.getInventoryNodeInfo(props.nodeId)
        setNodeData(nodeResponse)
        setIsNodeInfoLoading(false)
      } else if (isNodeInfoLoading && !nodeData && !props.nodeId) {
        setNodeData({ errorKey: 'snicNodeDoesNotExist' })
        setIsNodeInfoLoading(false)
      }
    }
    fetchNodeInfo()
  }, [appStore.portalInventoryApi, isNodeInfoLoading, nodeData, props.nodeId])

  const handleVisibleChange = (isTooltipVisible: boolean) => {
    if (!isTooltipVisible) {
      setNodeData(null)
      setIsNodeInfoLoading(false)
    } else {
      setIsNodeInfoLoading(true)
    }
  }

  const oc = style.override
  return (
    <Tooltip
      placement="left"
      overlay={
        !nodeData ? (
          <Spinner
            visible
            classes={curr => ({
              ...curr,
              root: classNames(curr.root, style.spinnerModifications),
            })}
          />
        ) : nodeData?.nodeInfo ? (
          <JSONPretty theme={jsonPrettyTheme} data={nodeData.nodeInfo} />
        ) : nodeData?.errorKey ? (
          <div className={style.serverError}>{t(`errors.${nodeData?.errorKey}`)}</div>
        ) : undefined
      }
      align={{
        offset: [0, -5],
      }}
      mouseLeaveDelay={0}
      trigger={['click']}
      onVisibleChange={handleVisibleChange}
      destroyTooltipOnHide={true}
    >
      <Button
        classes={curr => ({
          ...curr,
          root: classNames(curr.root, oc, style.extendTrialButtonCustomization),
        })}
        styleVariant="tertiary"
      >
        {t('manageInventory.checkLiveNodeInfo')}
      </Button>
    </Tooltip>
  )
})

const NODE_PER_PAGE = 10

const FILTER_OPTIONS = ['failed', 'successful', 'all'] as const

type FilterOption = (typeof FILTER_OPTIONS)[number]

interface NodeReportProps {
  viewJobName: string
  onClose: () => Promise<void>
  manageInventoryStore: ManageInventoryStore
}

const isNodeStatusCorrectFilter = (node: INodeInfo, filter: FilterOption) => {
  return filter === 'failed'
    ? node.status === 'FAIL'
    : filter === 'successful'
    ? node.status === 'SUCCESS'
    : true
}

type NodeTableRowKey = 'nodeId' | 'partnerId' | 'error' | 'status' | 'createdAt'

type TableSortArgument = { fieldName: NodeTableRowKey; direction: number }

export const NodeReport = observer((props: NodeReportProps) => {
  const { t } = useTranslation()
  const appStore = useStores()
  const currentUser = appStore?.authStore?.currentUser
  const { onClose, viewJobName } = props
  const { jobs, isLoading, serverError } = props.manageInventoryStore

  const [searchString, setSearchString] = React.useState('')
  const [nodeStatusFilter, setNodeStatusFilter] = React.useState<FilterOption>(() => {
    if (jobs?.find(j => j?.name === viewJobName)?.nodes?.find(n => n?.status === 'FAIL')) {
      return 'failed'
    }
    return 'all'
  })
  const [tablePage, setTablePage] = React.useState(0)
  const [sortParameters, setSortParameters] = React.useState<TableSortArgument>({
    fieldName: 'nodeId',
    direction: -1,
  })

  const viewJob = jobs.find(j => j.name === viewJobName)
  React.useEffect(() => {
    mixpanelActions.track('View node report modal - Open', {
      'Job Id': viewJobName,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!viewJob.nodes) {
      props.manageInventoryStore.getJobNodes(viewJob.name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (viewJob?.nodes?.find(n => n?.status === 'FAIL')) {
      setNodeStatusFilter('failed')
    }
  }, [viewJob?.nodes])

  const handleExportCSV = () => {
    const fileName = `${moment(viewJob.createdAt).utc().format('YYYYMMDHHmmss')}-${
      viewJob.name
    }-node-reassignment-job.csv`

    const headers = [
      {
        key: 'nodeId',
        display: 'POD_SN',
      },
      {
        key: 'partnerId',
        display: 'PARTNER_ID',
      },
      {
        key: 'groupIds',
        display: 'GROUP_IDS',
      },
      {
        key: 'model',
        display: 'NODE_MODEL',
      },
      {
        key: 'purchaseOrderNumber',
        display: 'PO_NO',
      },
      {
        key: 'partNumber',
        display: 'PART_NO',
      },
      {
        key: 'boxSerialNumber',
        display: 'BOX_SN',
      },
      {
        key: 'shipDate',
        display: 'SHIP_DATE',
      },
      {
        key: 'ethernetMac',
        display: 'ETH0_MAC',
      },
      {
        key: 'ethernet1Mac',
        display: 'ETH1_MAC',
      },
      {
        key: 'radioMac24',
        display: 'WIFI24_MAC',
      },
      {
        key: 'radioMac50',
        display: 'WIFI5_MAC',
      },
      {
        key: 'radioMac50L',
        display: 'WIFI5_MAC_L',
      },
      {
        key: 'radioMac50U',
        display: 'WIFI5_MAC_U',
      },
      {
        key: 'bluetoothMac',
        display: 'BT_MAC',
      },
      {
        key: 'radioMac60',
        display: 'WIFI6_MAC',
      },
      {
        key: 'radioMac60L',
        display: 'WIFI6_MAC_L',
      },
      {
        key: 'radioMac60U',
        display: 'WIFI6_MAC_U',
      },
      {
        key: 'ultraWideband',
        display: 'ULTRA_WIDEBAND',
      },
      {
        key: 'firmwareVersion',
        display: 'FIRMWARE_VERSION',
      },
      {
        key: 'residentialGateway',
        display: 'RESIDENTIAL_GATEWAY',
      },
      {
        key: 'status',
        display: 'Status',
      },
      {
        key: 'error',
        display: 'Message',
      },
    ]

    props.manageInventoryStore.exportCSV(headers, viewJob.nodes, fileName)
  }

  const oc = style.override

  const tableHeaders: Array<TableHeader & { fieldName?: NodeTableRowKey }> = [
    {
      name: t('manageInventory.nodeId'),
      fieldName: 'nodeId',
    },

    {
      name: t('accountManager.partnerId'),
      fieldName: 'partnerId',
    },
    {
      name: t('manageInventory.checkedAt'),
      fieldName: 'createdAt',
      render: row => new Date(row.createdAt).toLocaleString(),
    },
    {
      fieldName: 'status',
      name: t('common.status'),
      render: (row: Partial<INodeInfo>) => (
        <Status
          color={
            row.status === 'SUCCESS' ? 'ok' : row.status === 'IN_PROGRESS' ? 'warning' : 'error'
          }
          label={
            row.status === 'SUCCESS'
              ? t('manageInventory.success')
              : row.status === 'IN_PROGRESS'
              ? t('manageInventory.inProgress')
              : t('manageInventory.fail')
          }
        />
      ),
    },
  ]

  if (nodeStatusFilter === 'failed') {
    tableHeaders.push({
      name: t('errors.error'),
      fieldName: 'error',
      render: row => row.error || '',
    })
  }

  tableHeaders.push({
    name: '',
    sortable: false,
    render: row => (
      <Tooltip
        placement="right"
        overlay={<JSONPretty theme={jsonPrettyTheme} data={row} />}
        align={{
          offset: [0, -5],
        }}
        trigger={['click']}
        mouseLeaveDelay={0}
      >
        <Button
          classes={curr => ({
            ...curr,
            root: classNames(curr.root, oc, style.extendTrialButtonCustomization),
          })}
          styleVariant="tertiary"
        >
          {t('manageInventory.viewDetails')}
        </Button>
      </Tooltip>
    ),
  })

  if (currentUser.isEmployee) {
    tableHeaders.push({
      name: '',
      sortable: false,
      render: row => <LiveNodeCheck nodeId={row.nodeId} />,
    })
  }

  const searchFilteredData = viewJob?.nodes
    ?.filter(
      n =>
        isNodeStatusCorrectFilter(n, nodeStatusFilter) &&
        (matchesCaseInsensitive(n.nodeId, searchString) ||
          matchesCaseInsensitive(n.partnerId, searchString)),
    )
    .sort((d1, d2) =>
      (d1[sortParameters.fieldName]?.toLowerCase() || '') >
      (d2[sortParameters.fieldName]?.toLowerCase() || '')
        ? -1 * sortParameters.direction
        : sortParameters.direction,
    )

  if (tablePage && tablePage >= Math.ceil(searchFilteredData?.length / NODE_PER_PAGE)) {
    setTablePage(0)
  }

  return (
    <Modal
      isOpen={true}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      classes={(curr: any) => ({ ...curr, root: classNames(curr.root, style.modalWidth) })}
    >
      <ModalHeader
        classes={curr => ({
          ...curr,
          textWrapper: classNames(curr.textWrapper, style.headerTextMargin),
        })}
        title={t('manageInventory.nodeReportTitle', {
          jobId: viewJobName,
        })}
      />
      <ModalBody classes={curr => ({ ...curr, root: classNames(curr.root, style.modalBottom) })}>
        {isLoading || !viewJob?.nodes ? (
          <Loader theme="small" rootClassName={style.loaderRoot} />
        ) : (
          <>
            <SearchInputField
              id={'nodeSearchString1'}
              min={3}
              onChange={e => {
                setSearchString(e.target.value || '')
                setTablePage(0)
              }}
              placeholder={t('common.search')}
              messages={[{ status: 'hint', message: t('manageInventory.searchByNodeId') }]}
              value={searchString}
            />

            <div>{t('manageInventory.filterNodes')}</div>
            <Dropdown
              closeOnItemClick={true}
              openInPortal={true}
              iconLeft={<Icons.FilterIcon />}
              label={t(`common.${nodeStatusFilter}`)}
            >
              {FILTER_OPTIONS.map(option => {
                return (
                  <DropdownItem
                    key={option}
                    selected={nodeStatusFilter === option}
                    onClick={() => setNodeStatusFilter(option)}
                  >
                    {t(`common.${option}`)}
                  </DropdownItem>
                )
              })}
            </Dropdown>
            <div className={style.verticalSpace}></div>
            <div className={style.tableWrapper}>
              <Table
                classes={curr => ({
                  ...curr,
                  root: classNames(curr.root, oc, style.nodeReportTableRoot, style.tableMargin),
                  dataRow: classNames(curr.dataRow, style.override, style.rowHover),
                  cell: classNames(curr.cell, oc, style.tableCellCustomization),
                })}
                externalSort={true}
                onSortChange={sp => {
                  setSortParameters(sp as TableSortArgument)
                  setTablePage(0)
                }}
                truncateCellContent={false}
                headerRow={tableHeaders}
                dataRows={
                  searchFilteredData.slice(
                    tablePage * NODE_PER_PAGE,
                    (tablePage + 1) * NODE_PER_PAGE,
                  ) as any
                }
                noResultsMessage={t('header.noResults')}
              />
            </div>

            <div className={style.paginationWrapper}>
              <Pagination
                classes={curr => ({
                  ...curr,
                  root: classNames(curr.root, style.paginationCustomization),
                })}
                expandDirection="top"
                currentPage={tablePage}
                onPageSelect={setTablePage}
                totalPageCount={Math.ceil(searchFilteredData?.length / NODE_PER_PAGE)}
              />
            </div>
            <Button
              classes={curr => ({
                ...curr,
                root: classNames(curr.root, oc, style.exportButton),
              })}
              styleVariant="tertiary"
              onClick={() => handleExportCSV()}
            >
              {t('manageInventory.exportToCSV')}
            </Button>
            {serverError && <div className={style.serverError}>{serverError}</div>}
          </>
        )}
      </ModalBody>
      {false && <Loader />}
    </Modal>
  )
})
