import * as React from 'react'
import style from './add-edit-user.css'
import alertStyle from 'components/alert/alert.css'
import { Close } from 'components/icons/index'
import { Input } from 'components/input'
import { Button } from 'components/button'
import { DashboardUsersStore } from '../user-store'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import { Loader } from 'components/loader'
import { ModalLayerStore } from 'stores/modal-layer-store'
import { ConfirmDelete } from '../confirm-delete/confirm-delete'
import classNames from 'classnames'
import { ConfirmDeleteReturnValue } from 'modules/dashboard/account/account'
import { Dropdown, DropdownItem, Icons, TextToggler, Tooltip as UiTooltip } from 'plume-ui'
import { AddEditOption } from 'interfaces/api/portal/user-api'
import { ProgressBarLoader } from 'components/progress-bar/progress-bar-loader'
import { TogglerStyles as TextTogglerStyles } from 'plume-ui/dist/components/Toggler/TextToggler'
import { getCompanyAllowedApplications } from 'helpers/company-helpers'

export const displayConfirmDeleteModal = async (
  actionModalStore: ModalLayerStore,
  store: DashboardUsersStore,
  email?: string,
  userIdFromUsersTab?: string,
) => {
  return new Promise<ConfirmDeleteReturnValue>(res => {
    const onClose = async (closeButton: ConfirmDeleteReturnValue) => {
      actionModalStore.removeModal()
      return res(closeButton)
    }

    const onConfirmDelete = async () => {
      onClose(ConfirmDeleteReturnValue.CloseButton)
      if (!email) {
        await store.deleteUser(!!userIdFromUsersTab)
      } else {
        await store.forceDeleteUser(email)
      }
    }

    const { editedUserHasMultipleCompanies } = store
    const confirmText = editedUserHasMultipleCompanies
      ? 'users.deleteMultiCompanyMsg'
      : 'users.deleteSingleCompanyMsg'
    if (!!userIdFromUsersTab) {
      store.fillDeleteUserForm(userIdFromUsersTab)
    }

    actionModalStore.addModal(
      <div className={alertStyle.darkenBackground} key={actionModalStore.getKey()}>
        <ConfirmDelete
          confirmQuestion={'users.deleteConfirm'}
          confirmText={confirmText}
          onClose={() => onClose(ConfirmDeleteReturnValue.CloseButton)}
          onConfirm={() => onConfirmDelete()}
        />
      </div>,
    )
  })
}

interface AddEditUserProps {
  onClose: () => Promise<void>
  action: AddEditOption
}

export const AddEditUser = observer(({ onClose, action }: AddEditUserProps) => {
  const appStore = useStores()
  const store = appStore?.usersStore
  const currentUser = appStore?.authStore?.currentUser
  const { t } = useTranslation()

  const companyHasApplications = !!getCompanyAllowedApplications(currentUser.company)?.length
  const ndaSigned = !!currentUser.company.signedNdaTimestamp

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    store.validateAndSubmit(action)
  }

  const deleteUser = () => {
    handleConfirmDelete()
  }

  const handleConfirmDelete = () => {
    displayConfirmDeleteModal(appStore?.actionModalStore, store)
  }

  const handleForceDeleteUser = (e: React.MouseEvent) => {
    e.preventDefault()
    displayConfirmDeleteModal(appStore?.actionModalStore, store, store.email.value)
  }

  const suspendUser = () => {
    store.suspendUser()
  }

  const unsuspendUser = () => {
    store.unsuspendUser()
  }

  const activateUser = () => {
    store.activateUser()
  }

  const {
    email,
    firstName,
    lastName,
    jobTitle,
    team,
    serverError,
    isReleaseNotesContact,
    isServiceDisruptionContact,
    isSecurityContact,
    currentUserCanDelete,
    editedUserCanBeDeleted,
    editedUserStatus,
    editedUserHasMultipleCompanies,
    isLoadingModal,
    areAddUserRequiredFieldsEmpty,
    formHasError,
    submitUserProgress,
  } = store
  const teamDropdownLabel = team.value

  const stopPropagationPreventDefault = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const teamDropdownDisabled = !companyHasApplications || !ndaSigned

  const dropdownItems = appStore?.accountStore?.teams?.map(teamItem => {
    return (
      <DropdownItem
        key={teamItem.name}
        selected={team.value === teamItem.name}
        onClick={
          teamDropdownDisabled ? stopPropagationPreventDefault : () => team.onChange(teamItem.name)
        }
      >
        {teamItem.name}
      </DropdownItem>
    )
  })
  dropdownItems.push(
    <DropdownItem key="users.clearSelection" onClick={() => team.onChange('')}>
      <div className={style.dropdownClearSelection}>
        <span className={style.redText}> {t('users.clearSelection')} </span>
        <Icons.TrashIcon color="#fa1478" />
      </div>
    </DropdownItem>,
  )

  const oc = style.override
  const textTogglerClasses = (current: TextTogglerStyles) => ({
    ...current,
    root: classNames(current.root, oc, style.toggleRoot),
    toggleContainer: classNames(current.toggleContainer, oc, style.toggleAdjustment),
  })

  return (
    <div className={classNames(style.root)}>
      <div className={style.subTitle}>
        {t(action === 'add' ? 'users.addUserTitle' : 'users.editUserTitle')}
      </div>
      <div className={style.closeButton}>
        <Close className={style.closeButtonScaling} onClick={onClose} />
      </div>
      <form onSubmit={handleFormSubmit}>
        <div className={style.formInputs}>
          <div className={style.formInput}>
            <Input
              className={style.input}
              autoFocus
              label={t('common.firstName')}
              hasBeenValidated={firstName.hasBeenValidated}
              value={firstName.value}
              error={firstName.error || !!serverError}
              onChange={e => firstName.onChange(e.target.value)}
              onBlur={e => firstName.onChange(e.target.value.trim())}
            />
          </div>
          <div className={style.formInput}>
            <Input
              className={style.input}
              label={t('common.lastName')}
              hasBeenValidated={lastName.hasBeenValidated}
              value={lastName.value}
              error={lastName.error || !!serverError}
              onChange={e => lastName.onChange(e.target.value)}
              onBlur={e => lastName.onChange(e.target.value.trim())}
            />
          </div>
          <div className={classNames(style.formInput, action === 'edit' && style.inputDisabled)}>
            <Input
              className={style.input}
              label={t('common.emailAddress')}
              hasBeenValidated={email.hasBeenValidated}
              value={email.value}
              error={email.error || !!serverError}
              onChange={e => email.onChange(e.target.value)}
              onBlur={e => email.onChange(e.target.value.trim())}
              disabled={action === 'edit'}
            />
          </div>
          <div className={style.formInput}>
            <Input
              className={style.input}
              label={t('common.jobTitle')}
              hasBeenValidated={jobTitle.hasBeenValidated}
              value={jobTitle.value}
              error={jobTitle.error || !!serverError}
              onChange={e => jobTitle.onChange(e.target.value)}
              onBlur={e => jobTitle.onChange(e.target.value.trim())}
            />
          </div>
          <div className={classNames(style.formInput, style.checkboxInput)}>
            <div className={style.emailContactLabel}>
              {t('users.releaseNotesContact').toUpperCase()}
            </div>
            <UiTooltip label={t('users.releaseNotesContactInfoText')} overrideMaxCharacters={true}>
              <Icons.InfoIcon className={style.uiInfoIcon} />
            </UiTooltip>
            <TextToggler
              classes={textTogglerClasses}
              title={''}
              selected={isReleaseNotesContact.value}
              onToggle={() => isReleaseNotesContact.onChange(!isReleaseNotesContact.value)}
            />
          </div>
          <div className={classNames(style.formInput, style.checkboxInput)}>
            <div className={style.emailContactLabel}>
              {t('users.serviceDisruptionContact').toUpperCase()}
            </div>
            <UiTooltip
              label={t('users.serviceDisruptionContactInfoText')}
              overrideMaxCharacters={true}
            >
              <Icons.InfoIcon className={style.uiInfoIcon} />
            </UiTooltip>
            <TextToggler
              classes={textTogglerClasses}
              title={''}
              selected={isServiceDisruptionContact.value}
              onToggle={() =>
                isServiceDisruptionContact.onChange(!isServiceDisruptionContact.value)
              }
            />
          </div>
          <div className={style.rowSeparator} />
          <div className={classNames(style.formInput, style.checkboxInput)}>
            <div className={style.emailContactLabel}>
              {t('users.securityContact').toUpperCase()}
            </div>
            <UiTooltip label={t('users.securityContactInfoText')} overrideMaxCharacters={true}>
              <Icons.InfoIcon className={style.uiInfoIcon} />
            </UiTooltip>
            <TextToggler
              classes={textTogglerClasses}
              title={''}
              selected={isSecurityContact.value}
              onToggle={() => isSecurityContact.onChange(!isSecurityContact.value)}
            />
          </div>
        </div>
        {serverError && (
          <div className={style.serverError}>{t(`errors.${serverError}`, serverError)}</div>
        )}

        <div className={style.btnLabel}>{`${t('users.team')} | ${t('users.teamDescription')}`}</div>
        <div className={style.selectInfoContainer}>
          {!!appStore?.accountStore?.teams?.length ? (
            <Dropdown
              closeOnItemClick={true}
              disabled={teamDropdownDisabled}
              label={team.value ? t(teamDropdownLabel) : t('teams.pleaseSelect')}
            >
              {dropdownItems}
            </Dropdown>
          ) : (
            <div>{t('users.noTeams')}</div>
          )}
        </div>

        {action === 'add' && currentUser.isEmployee && !!serverError && (
          <div className={style.btnDiv}>
            <Button
              className={style.sendInviteBtn}
              onClick={e => handleForceDeleteUser(e)}
              disabled={isLoadingModal}
            >
              {t('btn.forceDeleteUser')}
            </Button>
          </div>
        )}
        <div className={style.btnDiv}>
          {isLoadingModal ? (
            submitUserProgress ? (
              <ProgressBarLoader
                width={submitUserProgress}
                title={t(action === 'add' ? 'users.addUserWait' : 'users.editUserWait')}
              />
            ) : (
              <Loader />
            )
          ) : undefined}
          <Button
            className={style.sendInviteBtn}
            type="submit"
            disabled={
              isLoadingModal ||
              (action === 'add' && areAddUserRequiredFieldsEmpty) ||
              (action === 'add' && formHasError)
            }
          >
            {t(action === 'add' ? 'btn.sendInvite' : 'btn.saveChanges')}
          </Button>
        </div>
      </form>
      {action === 'edit' && currentUserCanDelete && editedUserCanBeDeleted && (
        <div>
          {editedUserStatus === 'ACTIVE' && !editedUserHasMultipleCompanies && (
            <div>
              <div className={style.deleteBtn}>
                <Button onClick={suspendUser} disabled={isLoadingModal}>
                  {t('btn.suspendUser')}
                </Button>
              </div>
            </div>
          )}
          {editedUserStatus === 'SUSPENDED' && !editedUserHasMultipleCompanies && (
            <div>
              <div className={style.deleteBtn}>
                <Button onClick={unsuspendUser} disabled={isLoadingModal}>
                  {t('btn.unsuspendUser')}
                </Button>
              </div>
            </div>
          )}
          {editedUserStatus === 'DEPROVISIONED' && !editedUserHasMultipleCompanies && (
            <div>
              <div className={style.deleteBtn}>
                <Button onClick={activateUser} disabled={isLoadingModal}>
                  {t('btn.activateUser')}
                </Button>
              </div>
            </div>
          )}
          <div className={style.deleteBtn}>
            <Button onClick={deleteUser} disabled={isLoadingModal}>
              {t('btn.deleteUser')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
})
