import * as React from 'react'
import { Close } from 'components/icons/index'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import { mixpanelActions } from 'utils/mixpanelActions'
import style from './node-reassignment.css'
import { InputField, Button, Icons } from 'plume-ui'
import luStyle from '../account/logo-upload/logo-upload.css'
import { ManageInventoryStore } from './manage-inventory-store'

import JSONPretty from 'react-json-pretty'
import { Loader } from 'components/loader'
import * as Papa from 'papaparse'
import { InputMessage } from 'plume-ui/dist/components/InputField/InputField'
import { getDuplicateInArray } from 'helpers/general-helpers'

const jsonPrettyTheme = require('react-json-pretty/dist/monikai')
const { v4: uuidv4 } = require('uuid')

interface ReportJobProps {
  onClose: () => Promise<void>
  manageInventoryStore: ManageInventoryStore
}

export const ReportJob = observer((props: ReportJobProps) => {
  const appStore = useStores()
  const { t } = useTranslation()
  const company = appStore?.authStore?.currentUser?.company
  const { onClose, manageInventoryStore } = props

  const { isLoading } = manageInventoryStore

  const [reportName, setReportName] = React.useState('')
  const [nodeIds, setNodeIds] = React.useState<any[]>([])
  const [csvError, setCsvError] = React.useState('')

  const [previewDataEnabled, setPreviewDataEnabled] = React.useState(false)

  React.useEffect(() => {
    mixpanelActions.track('Inventory Check Nodes - Modal Open', {
      'Partner Id': company?.partnerId,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isReportNameDuplicate = !!manageInventoryStore.jobs.find(j => j.name === reportName)

  const reportNameInputFieldErrorMessages: Partial<InputMessage>[] = [
    {
      status: 'hint',
      message: t('manageInventory.jobNameMayContain'),
    },
  ]
  if (isReportNameDuplicate) {
    reportNameInputFieldErrorMessages.push({
      status: 'error',
      message: t('manageInventory.duplicateJobNamesNotAllowed'),
    })
  }

  const isReportNameTooLong = reportName?.length > 50
  if (isReportNameTooLong) {
    reportNameInputFieldErrorMessages.push({
      status: 'error',
      message: t('errors.max50CharsAllowed'),
    })
  }

  const isJobNameValid = !reportName || /^[a-zA-Z0-9]([A-Za-z0-9\-]*[a-zA-Z0-9])?$/.test(reportName)
  if (!isJobNameValid) {
    reportNameInputFieldErrorMessages.push({
      status: 'error',
      message: t('manageInventory.jobNameInvalid'),
    })
  }

  const submitDisabled =
    !nodeIds?.length ||
    !!csvError ||
    isReportNameDuplicate ||
    isReportNameTooLong ||
    !isJobNameValid

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (submitDisabled) {
      return
    }
    setPreviewDataEnabled(false)
    await manageInventoryStore.addReportJob(company.partnerId, {
      name: reportName || uuidv4(),
      nodeIds,
    })
  }

  const onCsvChangeHandler = async (event: React.FormEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files[0]
    if (file) {
      const nodesString = await file.text()
      const csvResult = Papa.parse<{ POD_SN: string }>(nodesString, {
        header: true,
        delimiter: ',',
        skipEmptyLines: true,
      })

      const maxRows = 1000
      const tooManyRows = csvResult.data.length > maxRows
      const duplicatedNodeId = getDuplicateInArray(csvResult.data.map(n => n.POD_SN))
      const missingColumnPOD_SN = !csvResult.meta.fields.includes('POD_SN')

      if (csvResult.errors.length || missingColumnPOD_SN || duplicatedNodeId || tooManyRows) {
        setCsvError(
          `${t('manageInventory.invalidCSV')}${
            csvResult.errors.length ? `\n${csvResult.errors[0].message}` : ''
          }${missingColumnPOD_SN ? `\n${t('manageInventory.missingPodSnColumn')}` : ''}${
            tooManyRows ? `\n${t('manageInventory.csvMaxRowsVariable', { maxRows })}` : ''
          }${
            duplicatedNodeId
              ? `\n${t('manageInventory.duplicatePodSerialNumber')} ${duplicatedNodeId}`
              : ''
          }`,
        )
        setNodeIds([])
        return
      }
      setCsvError('')
      setNodeIds(csvResult.data.map(item => item.POD_SN))
    }
  }

  const handleDownloadBlankCSV = () => {
    const fileName = 'blankExampleNodeCheck.csv'
    const columnLabelsArray = ['POD_SN']
    const headers = columnLabelsArray.map(l => ({ key: l, display: l }))
    props.manageInventoryStore.exportCSV(headers, [{ POD_SN: 'examplePodSN' }], fileName)
  }

  return (
    <div className={style.root}>
      <div className={style.subTitle}>{t('manageInventory.createReportJob')}</div>
      <div className={style.closeButton}>
        <Close onClick={onClose} />
      </div>

      <div style={{ marginBottom: 10 }}>{t('manageInventory.reportJobDescription')}</div>

      <InputField
        id={'reportName'}
        type="text"
        label={t('manageInventory.jobName')}
        value={reportName}
        required={true}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          e.stopPropagation()
          setReportName(e.target.value)
        }}
        messages={reportNameInputFieldErrorMessages}
      />

      <div>
        <Button styleVariant="superprimary" onClick={handleDownloadBlankCSV}>
          {t('manageInventory.downloadBlankCSV')}
        </Button>
        <input
          id="reportJobCsvFile"
          className={luStyle.fileInput}
          type="file"
          name="reportJobCsvFile"
          onChange={onCsvChangeHandler}
          accept=".csv"
        />
        <div className={luStyle.fileSelection}>
          <label htmlFor="reportJobCsvFile" className={luStyle.labelLogoTitle}>
            {!nodeIds?.length ? (
              t('manageInventory.uploadCsvHere')
            ) : (
              <>
                {t('manageInventory.csvUploadedSuccessfully')}{' '}
                <Icons.CheckedIcon height={18} width={18} />
              </>
            )}
          </label>
          <label htmlFor="reportJobCsvFile" className={luStyle.labelLogoText}>
            {t('logoUpload.selectFileForUpload')}
          </label>
        </div>
        {csvError && <div className={style.serverError}>{csvError}</div>}
        <div className={style.btnDiv}>
          <Button
            disabled={!nodeIds?.length}
            styleVariant="superprimary"
            onClick={() => {
              if (!!nodeIds?.length) setPreviewDataEnabled(!previewDataEnabled)
            }}
          >
            {t('manageInventory.previewData')}
          </Button>
        </div>
      </div>

      {previewDataEnabled && <JSONPretty theme={jsonPrettyTheme} data={nodeIds} />}

      <div className={style.btnDiv}>
        {isLoading && <Loader additionalStyle={{ zIndex: 2000 }} />}
        <Button styleVariant="superprimary" disabled={submitDisabled} onClick={handleFormSubmit}>
          {t('btn.submit')}
        </Button>
      </div>
    </div>
  )
})
